import type { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';

import type { View } from 'common/types/view';

import * as actions from './actions';
import { ActionTypes } from './actions';
import type { State } from '../types';

export type Actions = ActionType<typeof actions>;

export const addAssetUids = (state: State, { assetUids }: { assetUids: string[] }): State => ({
  ...state,
  assets: assetUids
    // filter out blanks and any assets we already have
    .filter((uid) => uid && !Object.keys(state.assets).some((key) => key === uid))

    // map to an array of objects
    .map((uid) => ({
      [uid]: {}
    }))

    // reduce the array into the current asset list
    .reduce((p, n) => ({ ...p, ...n }), state.assets)
});

export const assetLoaded = (
  state: State,
  { uid, view, error, warning }: { uid: string; view?: View; error?: string; warning?: JSX.Element }
): State => ({
  ...state,
  assets: {
    ...state.assets,
    [uid]: {
      view,
      error,
      warning
    }
  }
});

const removeAsset = (state: State, { uid }: { uid: string }): State => {
  // tricky JS is tricky... this is spreading state.assets, pulling out the key we want to delete, and keeping the rest
  // and then returning a new state with just the remaining assets
  const { [uid]: deleted, ...remaining } = state.assets;

  return {
    ...state,
    assets: remaining
  };
};

// We don't care that state can be undefined.
// @ts-expect-error TS(2322) FIXME: Type '(state: State, action: Actions) => State' is... Remove this comment to see the full error message
const reducer: Reducer<State, Actions> = (state: State, action: Actions) => {
  switch (action.type) {
    case ActionTypes.SetTargetDomainCname:
      return {
        ...state,
        targetDomainCname: action.payload.targetDomainCname
      };
    case ActionTypes.SetSkipChildMigration:
      return {
        ...state,
        skipChildMigration: action.payload.skipChildMigration
      };
    case ActionTypes.SetSkipAllButSpecified:
      return {
        ...state,
        skipAllButSpecified: action.payload.skipAllButSpecified
      };
    case ActionTypes.AddAssetUids:
      return addAssetUids(state, action.payload);
    case ActionTypes.AssetLoaded:
      return assetLoaded(state, action.payload);
    case ActionTypes.RemoveAsset:
      return removeAsset(state, action.payload);
    case ActionTypes.PerformMigration:
      return {
        ...state,
        doingMigration: true
      };
    case ActionTypes.MigrationFinished:
      return {
        ...state,
        doingMigration: false,
        migrationResponse: action.payload.response,
        migrationResponseRequestId: action.payload.requestId
      };
    default:
      return state;
  }
};

export default reducer;
